<template v-if="fetched_adv && fetched_teams">
  <div v-show="activeSlide === 1 || activeSlide === 2 || activeSlide === 3 || activeSlide === 4"
    class="generic-advertiser">
    <div>
      <h1 v-if="activeSlide === 1 || activeSlide === 3">Adverteerders Omzet {{ header }}</h1>
      <h1 v-if="activeSlide === 2 || activeSlide === 4">Adverteerders Aantal {{ header }}</h1>
    </div>
    <template v-for="(item, index) in getVerticals" :key="item.vertical">
      <AdvertiserVertical :vertical="item" :cp="certifiedPartnerId" :row="index" :active-slide="activeSlide" />
    </template>
    <div class="row-vertical">
      <div class="col-header">
        <h2>Totalen</h2>
      </div>
      <div v-if="activeSlide === 1" class="row-adverteerder">
        <div class="adverteerder">Totaal</div>
        <div></div>
        <div class="revenue-today">&euro;{{ formatFee(getSummaryAdvertisersTotal.fee_today) }}</div>
        <div class="revenue-week">&euro;{{ formatFee(getSummaryAdvertisersTotal.fee_this_week) }}</div>
        <div class="revenue-month">&euro;{{ formatFee(getSummaryAdvertisersTotal.fee_this_month) }}</div>
      </div>
      <div v-if="activeSlide === 2" class="row-adverteerder">
        <div class="adverteerder">Totaal</div>
        <div></div>
        <div class="revenue-today">{{ getSummaryAdvertisersTotal.cnt_today }}</div>
        <div class="revenue-week">{{ getSummaryAdvertisersTotal.cnt_this_week }}</div>
        <div class="revenue-month">{{ getSummaryAdvertisersTotal.cnt_this_month }}</div>
      </div>
      <div v-if="activeSlide === 3" class="row-adverteerder">
        <div class="adverteerder">Totaal</div>
        <div></div>
        <div class="revenue-today">&euro;{{ formatFee(getSummaryAdvertisersTotal.fee_today) }}</div>
        <div class="revenue-week">&euro;{{ formatFee(getSummaryAdvertisersTotal.fee_this_quarter) }}</div>
        <div class="revenue-month">&euro;{{ formatFee(getSummaryAdvertisersTotal.fee_this_half_year) }}</div>
      </div>
      <div v-if="activeSlide === 4" class="row-adverteerder">
        <div class="adverteerder">Totaal</div>
        <div></div>
        <div class="revenue-today">{{ formatFee(getSummaryAdvertisersTotal.cnt_today) }}</div>
        <div class="revenue-week">{{ formatFee(getSummaryAdvertisersTotal.cnt_this_quarter) }}</div>
        <div class="revenue-month">{{ formatFee(getSummaryAdvertisersTotal.cnt_this_half_year) }}</div>
      </div>
    </div>
    <!-- {{ getVerticals }} -->
  </div>
</template>

<script>
// import Mixin from '../shared/mixin';
import AdvertiserVertical from './AdvertiserVertical.vue';
import shared from '../shared/shared.js';
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useFetch } from '../composables/useFetch'

export default {
  components: { AdvertiserVertical },
  props: {
    activeSlide: {
      type: Number,
      required: false,
      default: 1
    },
    header: {
      type: String,
      required: false,
      default: 'Improvers'
    },
    certifiedPartnerId: {
      type: String,
      required: false,
      // default: '28835'
    },
  },
  setup(props) {
    const store = useStore();
    const { formatFee } = shared();
    const fetched_adv = ref(false);
    const fetched_teams = ref(false);


    onMounted(() => {
      // This should be dynamic certifiedPartnerId: '28835'
      console.log('cp id', props.certifiedPartnerId)
      useFetch(process.env.VUE_APP_SUMMARY_ADVERTISER, { addQueryParams: true, certifiedPartnerId: props.certifiedPartnerId })
        .then((response) => response.json())
        .then((result) => {
          store.dispatch('setSummaryAdvertisers', result)
          fetched_adv.value = true;
          console.log('loaded adv', true);
        }
        )
        .catch((err) => { console.log('err', err); });

      useFetch(process.env.VUE_APP_SUMMARY_TEAMS, { addQueryParams: true, certifiedPartnerId: props.certifiedPartnerId })
        .then((response) => response.json())
        .then((result) => {
          store.dispatch('setSummaryTeams', result)
          fetched_teams.value = true;
          console.log('loaded teams', true);
        })
        .catch((err) => { console.log('err', err); });

    })

    const getSummaryAdvertisersTotal = computed(function () {
      return { ...store.getters.totalAll(props.certifiedPartnerId) }
    })

    const getVerticals = computed(function () {
      return store.getters.getSummaryAdvertisers(props.certifiedPartnerId).filter((el) => el.cnt_this_half_year > 0).reduce((acc, cur) => {

        if (cur.advertiser.substring(0, 1) === 'E' || cur.advertiser.substring(0, 1) === 'R' || cur.advertiser.substring(0, 1) === 'S' || cur.advertiser.substring(0, 1) === 'D' || cur.advertiser.substring(0, 1) === 'H') {
          acc.find((el) => el.vertical === "energy").data.push(cur)
        }
        if (cur.advertiser.substring(0, 1) === 'C') {
          acc.find((el) => el.vertical === "charity").data.push(cur)
        }
        if (cur.advertiser.substring(0, 1) === 'M') {
          acc.find((el) => el.vertical === "media").data.push(cur)
        }
        if (cur.advertiser.substring(0, 1) === 'L') {
          acc.find((el) => el.vertical === "lottery").data.push(cur)
        }
        if (cur.advertiser.substring(0, 1) === 'T') {
          acc.find((el) => el.vertical === "telecom").data.push(cur)
        }
        return acc;
      }, [{ "vertical": "energy", "data": [] }, { "vertical": "charity", "data": [] }, { "vertical": "media", "data": [] }, { "vertical": "lottery", "data": [] }, { "vertical": "telecom", "data": [] }])
        .filter((item) => item.data.length > 0)
        .map((item) => {
          return {
            "vertical": item.vertical, "data": item.data.sort((a, b) => b.fee_this_month - a.fee_this_month)
          }
        })
    })

    return { formatFee, getSummaryAdvertisersTotal, getVerticals };

  }

};

</script>

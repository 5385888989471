<template>
    <div>
        <div class="col-header">
            <h1 v-if="thisTeam == 'Cherries'">Top {{ thisTeam }}</h1>
            <h1 v-else>Top partners {{ thisTeam }}</h1>
            <div class="revenue-today">Vandaag</div>
            <div class="revenue-week">Week</div>
            <div class="revenue-quarter">Kwartaal</div>
        </div>
        <transition-group name="list-partner">
                <div v-for="(item, index) in topPartners" :key="item.partner_id" class="row-partners list-partner-item"
                    :class="getRowComplianceClass(item.compliance)">
                    <div class="ranking">{{index + 1}}.</div>
                    <div v-if="item.certified_partner_id" class="name">{{ item.first_name }} {{ item.last_name }}</div>
                    <div v-else class="name">{{ item.company_name }}</div>
                    <div class="champion"><div :class="{ 'champion-logo': item.champion }"></div></div>
                    <div class="diamond"><div :class="{ 'diamond-logo': item.diamond }"></div></div>
                    <div class="award"><div class="award-logo">{{item.award}}</div></div>
                    <div class="compliance" :class="getItemComplianceClass(item.compliance)">{{formatCompliance(item.compliance)}}</div>
                    <div class="revenue-today">&euro;{{formatFee(item.fee_partner_today)}}</div>
                    <div class="revenue-week">&euro;{{formatFee(item.fee_partner_this_week)}}</div>
                    <div class="revenue-quarter">&euro;{{formatFee(item.fee_partner_this_quarter)}}</div>
                </div>
        </transition-group>
    </div>

</template>

<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import shared from '../shared/shared.js'

export default {
    name: 'TopPartners',
    components: {
    },
    props: {
        certifiedPartnerId: {
            type: String,
            required: false,
        },
        thisTeam: {
            type: String,
            required: false,
            default: 'Improvers'
        },
        mode: {
            type: String,
            required: false,
            default: 'fee'
        },
        // toggle: {
        //     type: Boolean,
        //     required: false,
        //     default: false
        // },
    },
    setup(props) {
        const { formatFee, getItemComplianceClass, getRowComplianceClass, formatCompliance } = shared()
        const store = useStore();

        const topPartners = computed(function () {
            if (props.certifiedPartnerId) {
                console.log(store.getters.partnerBig.filter((el) => el.certified_partner_id === props.certifiedPartnerId).slice(0, 80))
                return store.getters.partnerBig.filter((el) => el.certified_partner_id === props.certifiedPartnerId).slice(0, 24)
            } else {
                return store.getters.partnerBig.slice(0, 24)
            }
        }
        )

        const format = (val) => {
            console.log(props.mode)
            return props.mode === 'fee' ? '€' + formatFee(val) : val;
        }

        onMounted(function () {
            //console.log(store.getters.partnerBig.slice(0, 24))
            console.log(props.thisTeam)
            // toggle()
        })
        // return { topPartners, format, getItemComplianceClass, getRowComplianceClass, formatCompliance, nav_toggle }
        return { topPartners, format, getItemComplianceClass, getRowComplianceClass, formatCompliance, formatFee }
    }
}


</script>
export default {
  methods: {
    // getProgress(current, total) {

    //   console.log('CURRENT ',(total/(current))*100, Math.round((total/(current))*100))

    //   return `${Math.min(Math.round((total/(current))*100), 100)}`;
    // },
    // this is used for circle diagrams svg progress
    // getStrokeDashArray(total, current) {
    //   const pct = this.getProgress(total, current);
    //   return `${pct}, 100`;
    // },
    getItemComplianceClass(value) {
      if (value === null) {
        return '';
      }
      if (value >= 95) {
        return 'compliance-pos';
      }
      if (value < 90) {
        return 'compliance-neg';
      }
      return 'compliance-med';
    },
    getRowComplianceClass(value) {
      if (value === null) {
        return '';
      }
      if (value >= 95) {
        return 'row-compliance-pos';
      }
      if (value < 90) {
        return 'row-compliance-neg';
      }
      return 'row-compliance-med';
    },
    formatCompliance(value) {
      if (value === null) {
        return '';
      }
      return `${Math.round(value)}%`;
    },
    formatFee(value) {
      return Math.round(value).toLocaleString('nl-NL', { maximumFractionDigits: 0, minimumFractionDigits: 0 }); // '1.234'
    },
    chooseColor(size) {
      const colors = ['blue', 'mint', 'orange', 'salsa'];
      return colors[size % 4];
    },
    get_url(url) {
      const token = new URL(window.location.href).searchParams.get('token');
      if (token !== null) {
        return `${url}?token=${token}`;
      }
      return url;
    }

  }
};

<template>
  <footer>
    <div class="footer-start-block">
      <img src="@/assets/images/nos-logo.png" />
    </div>
    <div class="footer-content" >
      <p><template v-for="(item, index) in feeds" :key="item" :index="index" >

          <span class="footer-span" :class="{ 'footer-span-active': index === currentPage }">{{ item.data }}</span>
        </template>
      </p>
    </div>
    <div class="footer-end-block">
      <p id="time">{{ currentTime }}</p>
      <a ref="downloadLink" @click="download">
        <img :src="footerLogo" />
      </a>
    </div>
  </footer>
</template>

<script>
// import Mixin from '../shared/mixin';
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'

export default ({
  name: 'Footer',
  props: ['pager', 'currentPage'],

  setup(props) {

    const store = useStore();

    const currentTime = computed(function () {
      return store.state.current_time;
    });

    const feeds = computed(function () {
      return props.pager
    });

    onMounted(async function () {
      console.log(props.pager)
    });

    const footerLogo = computed(function () {
      const theme = store.state.theme;
      if (theme == 'improvers') {
        return 'https://improvers-prod-images-bucket.s3.eu-central-1.amazonaws.com/logo/rgb_im_white.svg';
      } else {
        return 'https://improvers-prod-images-bucket.s3.eu-central-1.amazonaws.com/logo/rgb_' + theme + '_beeldmerk_square_white.svg';
      }
    });

    const feedLogo = computed(function () {
      const theme = store.state.theme;
      if (theme == 'improvers') {
        return 'https://improvers-prod-images-bucket.s3.eu-central-1.amazonaws.com/logo/rgb_im_beeldmerk_square_darkblue.svg';
      } else {
        return 'https://improvers-prod-images-bucket.s3.eu-central-1.amazonaws.com/logo/rgb_' + theme + '_beeldmerk_square_darkpurple.svg';
      }
    });


    return { currentTime, feeds, footerLogo, feedLogo }
  }

});


// export default {
//   name: 'Footer',
//   // mixins: [Mixin],
//   computed: {
//     currentTime() {
//       return this.$store.state.current_time;
//     },
//     feeds() {
//       return this.$store.state.feeds.items;
//     },
//   },
// };

</script>


<style scoped>

footer .footer-content p {
  animation: unset;
}

footer .footer-span {
  opacity: 0.5;
}

footer .footer-span-active {
  opacity: 1;
  text-decoration: underline;
}

footer .footer-start-block {

  display: none;

}

footer .footer-end-block {
  background-color: #0C2E5C;
}

footer {
  background-color: #0C2E5C;
  /* grid-template-columns: 200px; auto 200px; */
  grid-template-columns: auto 200px;
}

footer .footer-content p span {
    padding: 0 20px;
}

</style>



<template>
    <div>
        <div class="details">

            <div class="a1 block">
                <div class="col-header">
                    <h1 v-show="toggle === false">Omzet Sales</h1>
                    <h1 v-show="toggle === true">Aantallen Sales</h1>
                    <div class="revenue-last-week"><span class="legend"></span>Deze periode</div>
                    <div class="revenue-current-week"><span class="legend"></span>Vorige periode</div>
                </div>
                <!-- <h4>PER UUR</h4>
                <Barchart v-if="chartHour" :toggle="toggle" :axis="chart1axis" :data="chartHour" :cnt_scale="100" :fee_scale="5000" />
                 -->
                <h4>CUMULATIEF PER UUR</h4>
                <Barchart v-if="chartHourCumm" class="bh" :toggle="toggle" :axis="chart1axis" :data="chartHourCumm"
                    :cnt_scale="200" :fee_scale="10000" />
                <!-- <h4>CUMULATIEF PER KWARTAAL</h4>
                <Barchart v-if="chartQuarterCumm" :axis="chart3axis" :data="chartQuarterCumm" :cnt_scale="4000" :fee_scale="220000" /> -->
                <h4>CUMULATIEF PER WEEK</h4>
                <Barchart v-if="chartWeekCumm" class="bw" :toggle="toggle" :axis="chart2axis" :data="chartWeekCumm"
                    :cnt_scale="10" :fee_scale="10000" />
                <!-- <Barchart :axis="chart3axix" :data="{}" /> -->
                <h4>CUMULATIEF PER KWARTAAL</h4>
                <Barchart v-if="chartQuarterCumm2" class="bq" :toggle="toggle" :axis="chart3axis" :data="chartQuarterCumm2"
                    :cnt_scale="1000" :fee_scale="100000" />
            </div>
            <div v-for="team in [...teams, totalTeams]" :key="team" class="b1 block">
                <h2>{{ team.team }}</h2>
                <div class="team-content">
                    <div>
                        <h4>Vandaag</h4>
                        <h1 v-show="toggle === false" :class="getItemComplianceClass(team.score)">€
                            {{ formatFee(team.fee_today) }}</h1>
                        <h1 v-show="toggle === true" :class="getItemComplianceClass(team.score)">{{ team.cnt_today }}</h1>
                    </div>
                    <div>
                        <h4>Week</h4>
                        <h1 v-show="toggle === false" :class="getItemComplianceClass(team.score)">€
                            {{ formatFee(team.fee_this_week) }}</h1>
                        <h1 v-show="toggle === true" :class="getItemComplianceClass(team.score)">{{ team.cnt_this_week }}</h1>
                    </div>
                    <div>
                        <h4>Maand</h4>
                        <h1 v-show="toggle === false" :class="getItemComplianceClass(team.score)">€
                            {{ formatFee(team.fee_this_month) }}</h1>
                        <h1 v-show="toggle === true" :class="getItemComplianceClass(team.score)">{{ team.cnt_this_month }}
                        </h1>
                    </div>
                    <div>
                        <h4>Kwartaal</h4>
                        <h1 v-show="toggle === false" :class="getItemComplianceClass(team.score)">€
                            {{ formatFee(team.fee_this_quarter) }}</h1>
                        <h1 v-show="toggle === true" :class="getItemComplianceClass(team.score)">{{ team.cnt_this_quarter }}
                        </h1>
                    </div>
                    <div>
                        <h4>Halfjaar</h4>
                        <h1 v-show="toggle === false" :class="getItemComplianceClass(team.score)">€
                            {{ formatFee(team.fee_this_half_year) }}</h1>
                        <h1 v-show="toggle === true" :class="getItemComplianceClass(team.score)">{{ team.cnt_this_half_year }}
                        </h1>
                    </div>
                    <div>
                        <h4>Compliance</h4>
                        <h1 :class="getItemComplianceClass(team.score)">{{ Math.floor(team.score) }}%</h1>
                    </div>

                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Barchart from '../components/Barchart.vue'
import Footer from '../components/Footer.vue'
import { useStore } from 'vuex'
import { computed, onMounted, ref } from 'vue'
import shared from '../shared/shared.js'

export default {
    name: 'Detail',
    components: { Barchart, Footer },
    setup() {

        const { formatFee, getItemComplianceClass } = shared()
        const chart1axis = { x: ['09:00', '12:00', '13:00', '16:00', '19:00', '22:00'] }
        const chart2axis = { x: ['MA', 'DI', 'WO', 'DO', 'VR', 'ZA'] }
        const toggle = ref(false);
        const toggle_timer = ref(false);


        const chart3axis = computed(function () {
            // console.log('x', chartQuarterCumm.value.values.map((el,index) => index + 1))
            // console.log('test', chartQuarterCumm.value.values)
            if (chartQuarterCumm.value.values.length > 0) {
                return { x: chartQuarterCumm.value.values.map((el, index) => index + 1) }
            }
            return false
        })

        const store = useStore();

        function reducer(acc, cur) {
            acc.cnt += cur.cnt
            acc.fee_partner += cur.fee_partner
            return acc;
        }

        onMounted(function () {
            setupTimer()
        })

        function setupTimer() {
            if (toggle_timer.value === false) {
                toggle_timer.value = setInterval(() => {
                    toggle.value = !toggle.value
                }, 12000);
            }
        }

        const teams = computed(function () {
            // console.log(store.getters.getSummaryTeams)
            // const teamsy = process.env.VUE_APP_TEAMS.split(',').map((el) => el.toLowerCase())
            return store.getters.getSummaryTeams.filter((el) => el.team != '' && el.team.toLowerCase() != 'onbekend' && el.team.toLowerCase() != 'thuis').map((team) => {
                const compl = store.getters.getComplianceTeam.find((el) => el.team == team.team)
                let cnt = 0
                let score = 100.0

                if (compl && compl.partners) {
                    cnt = compl.partners.length
                    // console.log('partners', compl.partners)
                    score = compl.partners.reduce((a, b) => {
                        return a + + b.compliance // force convert number
                    }, 0)
                }
                return { ...team, 'score': Math.floor(score / Math.max(cnt, 1)) }
            }).sort((a, b) => b.fee_this_week - a.fee_this_week)
        })


        const totalTeams = computed(function () {
            const val = teams.value.reduce((acc, cur) => {
                acc.fee_today += cur.fee_today,
                    acc.cnt_today += cur.cnt_today,
                    acc.fee_this_week += cur.fee_this_week,
                    acc.cnt_this_week += cur.cnt_this_week,
                    acc.fee_this_month += cur.fee_this_month,
                    acc.cnt_this_month += cur.cnt_this_month,
                    acc.fee_this_year += cur.cnt_this_month,
                    acc.cnt_this_year += cur.cnt_this_year,
                    acc.fee_this_quarter += cur.fee_this_quarter,
                    acc.cnt_this_quarter += cur.cnt_this_quarter,
                    acc.fee_this_half_year += cur.fee_this_half_year,
                    acc.cnt_this_half_year += cur.cnt_this_half_year,
                    acc.score += cur.score
                return acc;
            }, {
                team: 'Totaal',
                fee_today: 0.0,
                cnt_today: 0,
                fee_this_week: 0.0,
                cnt_this_week: 0,
                fee_this_month: 0.0,
                cnt_this_month: 0,
                fee_this_year: 0.0,
                cnt_this_year: 0,
                fee_this_quarter: 0.0,
                cnt_this_quarter: 0,
                fee_this_half_year: 0.0,
                cnt_this_half_year: 0,
                score: 0
            })
            val.score = val.score / 4
            return val
        })

        // the base data is managed in the store, this are the detail filters
        const chartHour = computed(function () {
            const today = store.getters.getChartToday

            const hour9last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 0.0 && el.hour <= 9.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
            const hour9current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 0.0 && el.hour <= 9.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
            const hour12last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 9.0 && el.hour <= 12.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
            const hour12current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 9.0 && el.hour <= 12.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
            const hour13last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 12.0 && el.hour <= 13.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
            const hour13current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 12.0 && el.hour <= 13.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
            const hour16last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 13.0 && el.hour <= 16.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
            const hour16current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 13.0 && el.hour <= 16.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
            const hour19last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 16.0 && el.hour <= 19.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
            const hour19current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 16.0 && el.hour <= 19.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
            const hour22last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 19.0 && el.hour <= 24.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
            const hour22current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 19.0 && el.hour <= 24.0).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })

            const ar = [hour9last, hour9current, hour12last, hour12current, hour13last, hour13current, hour16last, hour16current, hour19last, hour19current, hour22last, hour22current]
            const max_fee = Math.max.apply(Math, ar.map(function (el) { return el.fee_partner; }))
            const max_cnt = Math.max.apply(Math, ar.map(function (el) { return el.cnt; }))
            // console.log('chart_hour',max_fee,max_cnt)

            return {
                values: [
                    { data1: hour9current, data2: hour9last },
                    { data1: hour12current, data2: hour12last },
                    { data1: hour13current, data2: hour13last },
                    { data1: hour16current, data2: hour16last },
                    { data1: hour19current, data2: hour19last },
                    { data1: hour22current, data2: hour22last }
                ], max_fee: max_fee, max_cnt: max_cnt
            }
        })


        const chartHourCumm = computed(function () {

            const today = store.getters.getChartToday
            // console.log(today)
            if (store.state.current_time != null) {

                const current_hour = store.state.current_time.split(':')[0]

                const hour9last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 0 && el.hour <= 9).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
                const hour9current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 0 && el.hour <= 9).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
                // console.log('9',hour9last)

                const data9current = current_hour >= 9 ? { 'cnt': hour9current.cnt, 'fee_partner': hour9current.fee_partner } : { 'cnt': 0, 'fee_partner': 0.0 }
                const hour12last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 9 && el.hour <= 12).reduce(reducer, { 'cnt': hour9last.cnt, 'fee_partner': hour9last.fee_partner })
                const hour12current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 9 && el.hour <= 12).reduce(reducer, data9current)
                // console.log('12',hour12last)

                const data12current = current_hour >= 12 ? { 'cnt': hour12current.cnt, 'fee_partner': hour12current.fee_partner } : { 'cnt': 0, 'fee_partner': 0.0 }
                const hour13last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 12 && el.hour <= 13).reduce(reducer, { 'cnt': hour12last.cnt, 'fee_partner': hour12last.fee_partner })
                const hour13current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 12 && el.hour <= 13).reduce(reducer, data12current)
                // console.log('13',hour13last)

                const data13current = current_hour >= 13 ? { 'cnt': hour13current.cnt, 'fee_partner': hour13current.fee_partner } : { 'cnt': 0, 'fee_partner': 0.0 }
                const hour16last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 13 && el.hour <= 16).reduce(reducer, { 'cnt': hour13last.cnt, 'fee_partner': hour13last.fee_partner })
                const hour16current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 13 && el.hour <= 16).reduce(reducer, data13current)
                // console.log('16',hour16last)
                // console.log('testing', hour16current, hour12current, hour13current)

                const data16current = current_hour >= 16 ? { 'cnt': hour16current.cnt, 'fee_partner': hour16current.fee_partner } : { 'cnt': 0, 'fee_partner': 0.0 }
                const hour19last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 16 && el.hour <= 19).reduce(reducer, { 'cnt': hour16last.cnt, 'fee_partner': hour16last.fee_partner })
                const hour19current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 16 && el.hour <= 19).reduce(reducer, data16current)
                // console.log('19',hour16last)

                const data19current = current_hour >= 19 ? { 'cnt': hour19current.cnt, 'fee_partner': hour19current.fee_partner } : { 'cnt': 0, 'fee_partner': 0.0 }
                const hour22last = store.getters.getChartHour.filter((el) => el.global_week != today.global_week && el.hour > 19 && el.hour <= 24).reduce(reducer, { 'cnt': hour19last.cnt, 'fee_partner': hour19last.fee_partner })
                const hour22current = store.getters.getChartHour.filter((el) => el.global_week == today.global_week && el.hour > 19 && el.hour <= 24).reduce(reducer, data19current)


                const ar = [hour9last, hour9current, hour12last, hour12current, hour13last, hour13current, hour16last, hour16current, hour19last, hour19current, hour22last, hour22current]
                const max_fee = Math.max.apply(Math, ar.map(function (el) { return el.fee_partner; }))
                const max_cnt = Math.max.apply(Math, ar.map(function (el) { return el.cnt; }))
                // console.log('chart_hour_cumm',max_fee,max_cnt)

                return {
                    values: [
                        { data1: hour9current, data2: hour9last },
                        { data1: hour12current, data2: hour12last },
                        { data1: hour13current, data2: hour13last },
                        { data1: hour16current, data2: hour16last },
                        { data1: hour19current, data2: hour19last },
                        { data1: hour22current, data2: hour22last }
                    ], max_fee: max_fee, max_cnt: max_cnt
                }

            } else {
                return []
            }


        })

        const chartWeekCumm = computed(function () {

            const today = store.getters.getChartToday

            const ma_last = store.getters.getChartWeek.filter((el) => el.global_week != today.global_week && el.weekday_mon == 1).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
            const ma_current = store.getters.getChartWeek.filter((el) => el.global_week == today.global_week && el.weekday_mon == 1).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })

            const ma_prev_current = today.weekday_mon > 1 ? { 'cnt': ma_current.cnt, 'fee_partner': ma_current.fee_partner } : { 'cnt': 0, 'fee_partner': 0.0 }
            const di_last = store.getters.getChartWeek.filter((el) => el.global_week != today.global_week && el.weekday_mon == 2).reduce(reducer, { ...ma_last })
            const di_current = store.getters.getChartWeek.filter((el) => el.global_week == today.global_week && el.weekday_mon == 2).reduce(reducer, ma_prev_current)

            const di_prev_current = today.weekday_mon > 2 ? { 'cnt': di_current.cnt, 'fee_partner': di_current.fee_partner } : { 'cnt': 0, 'fee_partner': 0.0 }
            const wo_last = store.getters.getChartWeek.filter((el) => el.global_week != today.global_week && el.weekday_mon == 3).reduce(reducer, { ...di_last })
            const wo_current = store.getters.getChartWeek.filter((el) => el.global_week == today.global_week && el.weekday_mon == 3).reduce(reducer, di_prev_current)

            const wo_prev_current = today.weekday_mon > 3 ? { 'cnt': wo_current.cnt, 'fee_partner': wo_current.fee_partner } : { 'cnt': 0, 'fee_partner': 0.0 }
            const do_last = store.getters.getChartWeek.filter((el) => el.global_week != today.global_week && el.weekday_mon == 4).reduce(reducer, { ...wo_last })
            const do_current = store.getters.getChartWeek.filter((el) => el.global_week == today.global_week && el.weekday_mon == 4).reduce(reducer, wo_prev_current)

            const do_prev_current = today.weekday_mon > 4 ? { 'cnt': do_current.cnt, 'fee_partner': do_current.fee_partner } : { 'cnt': 0, 'fee_partner': 0.0 }
            const vr_last = store.getters.getChartWeek.filter((el) => el.global_week != today.global_week && el.weekday_mon == 5).reduce(reducer, { ...do_last })
            const vr_current = store.getters.getChartWeek.filter((el) => el.global_week == today.global_week && el.weekday_mon == 5).reduce(reducer, do_prev_current)

            const vr_prev_current = today.weekday_mon > 5 ? { 'cnt': vr_current.cnt, 'fee_partner': vr_current.fee_partner } : { 'cnt': 0, 'fee_partner': 0.0 }
            const za_last = store.getters.getChartWeek.filter((el) => el.global_week != today.global_week && (el.weekday_mon == 6 || el.weekday_mon == 7)).reduce(reducer, { ...vr_last })
            const za_current = store.getters.getChartWeek.filter((el) => el.global_week == today.global_week && (el.weekday_mon == 6 || el.weekday_mon == 7)).reduce(reducer, vr_prev_current)
            //console.log('za_current', za_current,today.global_week,store.getters.getChartWeek )

            const ar = [ma_last, ma_current, di_last, di_current, wo_last, wo_current, do_last, do_current, vr_last, vr_current, za_last, za_current]
            // console.log(za_current)
            const max_fee = Math.max.apply(Math, ar.map(function (el) { return el.fee_partner; }))
            const max_cnt = Math.max.apply(Math, ar.map(function (el) { return el.cnt; }))
            // console.log('chart_week',max_fee,max_cnt)
            return {
                values: [
                    { data1: ma_current, data2: ma_last },
                    { data1: di_current, data2: di_last },
                    { data1: wo_current, data2: wo_last },
                    { data1: do_current, data2: do_last },
                    { data1: vr_current, data2: vr_last },
                    { data1: za_current, data2: za_last }
                ], max_fee: max_fee, max_cnt: max_cnt
            }
        })

        const chartWeek = computed(function () {
            const today = store.getters.getChartToday

            // console.log('weel', store.getters.getChartWeek)

            const ma_last = store.getters.getChartWeek.filter((el) => el.global_week != today.global_week && el.weekday_mon == 1).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
            const ma_current = store.getters.getChartWeek.filter((el) => el.global_week == today.global_week && el.weekday_mon == 1).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
            const di_last = store.getters.getChartWeek.filter((el) => el.global_week != today.global_week && el.weekday_mon == 2).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
            const di_current = store.getters.getChartWeek.filter((el) => el.global_week == today.global_week && el.weekday_mon == 2).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
            const wo_last = store.getters.getChartWeek.filter((el) => el.global_week != today.global_week && el.weekday_mon == 3).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
            const wo_current = store.getters.getChartWeek.filter((el) => el.global_week == today.global_week && el.weekday_mon == 3).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
            const do_last = store.getters.getChartWeek.filter((el) => el.global_week != today.global_week && el.weekday_mon == 4).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
            const do_current = store.getters.getChartWeek.filter((el) => el.global_week == today.global_week && el.weekday_mon == 4).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
            const vr_last = store.getters.getChartWeek.filter((el) => el.global_week != today.global_week && el.weekday_mon == 5).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
            const vr_current = store.getters.getChartWeek.filter((el) => el.global_week == today.global_week && el.weekday_mon == 5).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
            const za_last = store.getters.getChartWeek.filter((el) => el.global_week != today.global_week && (el.weekday_mon == 6 || el.weekday_mon == 7)).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })
            const za_current = store.getters.getChartWeek.filter((el) => el.global_week == today.global_week && (el.weekday_mon == 6 || el.weekday_mon == 7)).reduce(reducer, { 'cnt': 0, 'fee_partner': 0.0 })

            //console.log('za_current', za_current,today.global_week,store.getters.getChartWeek )

            const ar = [ma_last, ma_current, di_last, di_current, wo_last, wo_current, do_last, do_current, vr_last, vr_current, za_last, za_current]
            // console.log(za_current)
            const max_fee = Math.max.apply(Math, ar.map(function (el) { return el.fee_partner; }))
            const max_cnt = Math.max.apply(Math, ar.map(function (el) { return el.cnt; }))
            // console.log('chart_week',max_fee,max_cnt)
            return {
                values: [
                    { data1: ma_current, data2: ma_last },
                    { data1: di_current, data2: di_last },
                    { data1: wo_current, data2: wo_last },
                    { data1: do_current, data2: do_last },
                    { data1: vr_current, data2: vr_last },
                    { data1: za_current, data2: za_last }
                ], max_fee: max_fee, max_cnt: max_cnt
            }
        })


        const chartQuarterCumm2 = computed(function () {
            const today = store.getters.getChartToday
            const firstWeekQuarter = store.getters.getFirstWeekQuarter

            const item = chartQuarterCumm.value;
            // console.log('v',item.values)
            const nval = item.values.reduce((acc, cur) => {

                if (acc.length > 0) {
                    const last = acc[acc.length - 1];
                    if (today.global_week <= acc.length + firstWeekQuarter) {
                        cur.data1.fee_partner += last.data1.fee_partner
                        cur.data1.cnt += last.data1.cnt
                    }
                    cur.data2.fee_partner += last.data2.fee_partner
                    cur.data2.cnt += last.data2.cnt
                }
                // console.log(cur)
                acc.push(cur)
                return acc;
            }, [])

            if (nval.length > 0) {
                const val = nval[nval.length - 1]
                const max_fee = val.data1.fee_partner > val.data2.fee_partner ? val.data1.fee_partner : val.data2.fee_partner;
                const max_cnt = val.data1.cnt > val.data2.cnt ? val.data1.cnt : val.data2.cnt;
                return { values: nval, max_fee: max_fee, max_cnt: max_cnt }
            } else {
                return { values: nval, max_fee: 0, max_cnt: 0 }
            }

        })

        const chartQuarterCumm = computed(function () {

            // const today = store.getters.getChartToday
            const first_week_quarter = store.getters.getFirstWeekQuarter
            // console.log(first_week_quarter)
            const highest = first_week_quarter.reduce((acc, cur) => { return Math.max(cur.cnt, acc) }, 0)
            const arrays = first_week_quarter.map((el) => { return Array.from({ length: highest }, (x, i) => i + el.global_week) })

            const temp = arrays.map((el) =>
                el.map((item, index) => {
                    const data = store.getters.getChartQuarter.find((el) => el.global_week == item)
                    if (data) {
                        return { 'week': item, 'index': index + 1, 'cnt': data.cnt, 'fee_partner': data.fee_partner, 'quarter': data.global_quarter }
                    }
                    else {
                        return { 'week': item, 'index': index + 1, 'cnt': 0, 'fee_partner': 0, 'quarter': 0 }
                    }
                }
                )
            ).map((el) => {
                return {
                    items: el,
                    sortweek: el[0].week
                }
            }).sort((a, b) => a.sortweek - b.sortweek)

            // const max_fee = Math.max.apply(Math, temp.map(function(el) { return el.fee_partner; }))
            // const max_cnt = Math.max.apply(Math, temp.map(function(el) { return el.cnt; }))

            // const max_cnt = Math.max.apply(Math, temp.map(function(el) { return el.cnt; }))
            if (temp && temp.length > 0) {
                const val = temp[1].items.map((el) => {
                    const data2 = temp[0].items.find((item) => item.index == el.index)
                    return { data1: { 'cnt': el.cnt, 'fee_partner': el.fee_partner }, data2: { 'cnt': data2.cnt, 'fee_partner': data2.fee_partner } }
                });

                const max_fee = val.reduce((acc, cur) => { return Math.max(cur.data1.fee_partner >= cur.data2.fee_partner ? cur.data1.fee_partner : cur.data2.fee_partner, acc) }, 0)
                const max_cnt = val.reduce((acc, cur) => { return Math.max(cur.data1.cnt >= cur.data2.cnt ? cur.data1.cnt : cur.data2.cnt, acc) }, 0)
                // const max_fee = Math.max.apply(Math, val.data1.map(function(el) { return el.fee_partner; }))
                // const max_cnt = Math.max.apply(Math, val.data1.map(function(el) { return el.cnt; }))

                return { values: val, max_fee: max_fee, max_cnt: max_cnt }
            } else {
                return { values: [], max_fee: 0, max_cnt: 0 }
            }
        })

        return { chart1axis, chart2axis, chart3axis, chartHour, chartQuarterCumm, chartQuarterCumm2, chartHourCumm, chartWeek, chartWeekCumm, teams, formatFee, getItemComplianceClass, toggle, totalTeams }
    },
}
</script>
<template>
  <footer>
    <div class="footer-start-block">
      <img src="@/assets/images/nos-logo.png" />
    </div>
    <div class="footer-content">
      <p><template v-for="(item, index) in feeds" :key="item">
          <span v-if="index > 0">
            <img :src="feedLogo" />
          </span>
          <span>{{ item }}</span>
        </template></p>
    </div>
    <div class="footer-end-block">
      <p id="time">{{ currentTime }}</p>
      <a ref="downloadLink" @click="download">
        <img :src="footerLogo" />
      </a>
    </div>
  </footer>
</template>

<script>
// import Mixin from '../shared/mixin';
import { computed } from 'vue'
import { useStore } from 'vuex'

export default ({
  name: 'Footer',
  setup() {

    const store = useStore();

    const currentTime = computed(function () {
      return store.state.current_time;
    });

    const feeds = computed(function () {
      return store.state.feeds.items;
    });

    const footerLogo = computed(function () {
      const theme = store.state.theme;
      if (theme == 'improvers') {
        return 'https://improvers-prod-images-bucket.s3.eu-central-1.amazonaws.com/logo/rgb_im_white.svg';
      } else {
        return 'https://improvers-prod-images-bucket.s3.eu-central-1.amazonaws.com/logo/rgb_' + theme + '_beeldmerk_square_white.svg';
      }
    });

    const feedLogo = computed(function () {
      const theme = store.state.theme;
      if (theme == 'improvers') {
        return 'https://improvers-prod-images-bucket.s3.eu-central-1.amazonaws.com/logo/rgb_im_beeldmerk_square_darkblue.svg';
      } else {
        return 'https://improvers-prod-images-bucket.s3.eu-central-1.amazonaws.com/logo/rgb_' + theme + '_beeldmerk_square_darkpurple.svg';
      }
    });


    return { currentTime, feeds, footerLogo, feedLogo }
  }

});


// export default {
//   name: 'Footer',
//   // mixins: [Mixin],
//   computed: {
//     currentTime() {
//       return this.$store.state.current_time;
//     },
//     feeds() {
//       return this.$store.state.feeds.items;
//     },
//   },
// };

</script>




<template>
    <div>
        <main class="vestiging">
            <div class="b is-hidden-tablet">
                <TeamTargets :revenue="team_revenue" :target="team_target" :thisTeam="thisTeam" />
            </div>
            <TeamCard class="a1 block" v-for="team in teams" :key="team" :header="team" />
            <div class="a3 block">
                <Reviews />
            </div>
            <div class="b is-hidden-mobile">
                <TeamTargets :revenue="team_revenue" :target="team_target" :thisTeam="thisTeam" />
            </div>

            <div class="c1 block">
                <div class="col-header">
                    <h1>Top Partners {{ thisTeam }}</h1>
                    <div class="revenue-today">Vandaag</div>
                    <div class="revenue-week">Week</div>
                    <div class="revenue-quarter">Kwartaal</div>
                </div>

                <transition-group name="list-partner">
                    <div v-for="(item, index) in topPartners" :key="item.partner_id" class="row-partners list-partner-item"
                        :class="getRowComplianceClass(item.compliance)">
                        <div class="ranking">{{index + 1}}.</div>
                        <div v-if="item.certified_partner_id" class="name">{{item.first_name}} {{item.last_name}}</div>
                        <div v-else class="name">{{item.company_name}}</div>
                        <div class="champion"><div :class="{ 'champion-logo': item.champion }"></div></div>
                        <div class="diamond"><div :class="{ 'diamond-logo': item.diamond }"></div></div>
                        <div class="award"><div class="award-logo">{{item.award}}</div></div>
                        <div class="compliance" :class="getItemComplianceClass(item.compliance)">{{formatCompliance(item.compliance)}}</div>
                        <div class="revenue-today">&euro;{{formatFee(item.fee_partner_today)}}</div>
                        <div class="revenue-week">&euro;{{formatFee(item.fee_partner_this_week)}}</div>
                        <div class="revenue-quarter">&euro;{{formatFee(item.fee_partner_this_quarter)}}</div>
                        <!-- <div class="ranking">{{ index + 1 }}.</div>
                        <div class="name">{{ item.first_name }} {{ item.last_name }}</div>
                        <div class="compliance" :class="getItemComplianceClass(item.compliance)">
                            {{ formatCompliance(item.compliance) }}</div>
                        <div class="revenue-today">&euro;{{ formatFee(item.fee_partner_this_week) }}</div>
                        <div v-show="toggle === false" class="revenue-week">&euro;{{
                            formatFee(item.fee_partner_today)
                        }}
                        </div>
                        <div v-show="toggle === true" class="revenue-week">
                            &euro;{{ formatFee(item.fee_partner_this_quarter) }}</div> -->
                    </div>
                </transition-group>



            </div>

        </main>
        <Footer />
    </div>
</template>

<script>
import { onMounted, computed, ref } from 'vue'
import Footer from '../components/Footer.vue';
import TeamCard from '../components/TeamCard.vue';
import Reviews from '../components/Reviews.vue';
import TeamTargets from '../components/TeamTargets.vue';
import { useStore } from 'vuex'
import shared from '../shared/shared.js'

export default {
    name: 'Team',
    components: {
        Footer,
        TeamCard,
        Reviews,
        TeamTargets
    },
    setup() {
        const store = useStore()
        const thisTeam = ref('');
        const toggle = ref(false);
        const toggle_timer = ref(false);
        const { formatFee, getItemComplianceClass, getRowComplianceClass, formatCompliance } = shared()


        onMounted(function () {
            setupTeams()
            setupTimer()
        })

        const teams = computed(function () {
            return store.state.teams.filter((el) => el.toLowerCase() !== thisTeam.value.toLowerCase());
        })

        const team_revenue = computed(function () {
            return store.getters.getSummaryTeams.find((el) => el.team.toLowerCase() === thisTeam.value.toLowerCase());
        })

        const team_target = computed(function () {
            const val = store.getters.getTargets.find((el) => String(el.team).toLowerCase() === thisTeam.value.toLowerCase());
            if (val) {
                return val
            } else {
                return { iso_month: 0, iso_week: 0, iso_year: 0, month_target: 0, quarter_target: 0, team: thisTeam.value.toLowerCase(), week_target: 0, year_target: 0, half_year_target: 0 }
            }
        })

        const topPartners = computed(function () {
            return store.getters.partnerBig.filter((el) => el.team.toLowerCase() === thisTeam.value.toLowerCase()).slice(0, 24)
        }
        )

        function setupTeams() {
            const url_team = new URL(window.location.href).searchParams.get('team');
            if (url_team) {
                thisTeam.value = url_team
            }
            store.dispatch('setTeams', process.env.VUE_APP_TEAMS.split(','));
        }

        function setupTimer() {
            if (toggle_timer.value === false) {
                toggle_timer.value = setInterval(() => {
                    toggle.value = !toggle.value
                }, 12000);
            }
        }

        return { teams, thisTeam, team_revenue, team_target, formatFee, getItemComplianceClass, getRowComplianceClass, formatCompliance, topPartners, toggle }
    },
}
</script>

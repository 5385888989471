<template>
  <div>
    <main class="charity">
        <div class="full-block">
            <div class="vert-align-wrap">
                <h1>
                    Charity All-In Bonus
                </h1>
            </div>
        </div>

        <div v-for="charity_target in charity_targets" :key="charity_target.advertiser" class="block">
            <div class="vert-align-wrap">
                <div class="margin-top-min50">
                    <h2>{{charity_target.advertiser}}</h2>
                    <Chart :revenue="charity_target.cnt_this_week" :target="charity_target.target"/>
                    <div class="charity-chart-details">
                        <h2 class="revenue">{{ charity_target.cnt_this_week }}</h2>
                        <p>van</p>
                        <h2 class="revenue-target">{{ charity_target.target }}</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="full-block">
            <div class="vert-align-wrap">
                <h2 v-if="total_targets.count_100_pct != total_targets.total_count && (total_targets.count_100_pct > 1 || total_targets.count_100_pct == 0)"  >
                    Op dit moment zijn <span class="higlight-count">{{total_targets.count_100_pct}}</span> van de <span class="higlight-count">{{total_targets.total_count}}</span> charity targets behaald!
                </h2>
                <h2 v-if="total_targets.count_100_pct != total_targets.total_count && total_targets.count_100_pct == 1"  >
                    Op dit moment is <span class="higlight-count">{{total_targets.count_100_pct}}</span> van de <span class="higlight-count">{{total_targets.total_count}}</span> charity targets behaald!
                </h2>
                <h2 v-if="total_targets.count_100_pct == total_targets.total_count" >
                    De charity all-in bonus is behaald!
                </h2>
            </div>
        </div>
    </main>
    <Footer />
    </div>
</template>

<script>
import { computed } from 'vue'
import Footer from '../components/Footer.vue';
// import TeamCard from '../components/TeamCard.vue';
// import Reviews from '../components/Reviews.vue';
import Chart from '../components/Chart.vue';
import { useStore } from 'vuex'
import shared from '../shared/shared.js'

export default {

    name: 'Team',
    components: {
        Chart,
        Footer
    },
    setup() {
        const { getProgress } = shared()
        const store = useStore()

        const { formatFee } = shared()

        const charity_targets = computed(function() {
            let sumAdv = store.getters.getSummaryAdvertisers.filter((el) => el.advertiser.substring(0, 1) === 'C')
            let retval = sumAdv.reduce((acc, cur) => {
                let target = store.getters.getTargets.find((el) => { return el.advertiser == cur.advertiser})
                let target_count = target ? target.count_week : 0
                acc.push({ advertiser: cur.advertiser_name, cnt_this_week: cur.cnt_this_week, target: target_count })
                return acc
            },[])
            return retval
        })

        const total_targets = computed(function() {
            let total_count = charity_targets.value.length
            let count_100_pct = charity_targets.value.reduce((acc, cur) =>
                {
                    console.log(getProgress(cur.target, cur.cnt_this_week))
                    if (getProgress(cur.target, cur.cnt_this_week) >= 100) {
                        acc += 1
                    }
                    return acc;
                }
            ,0)
            return { total_count: total_count, count_100_pct: count_100_pct }
        })


        return { charity_targets, formatFee, total_targets }
    },
}
</script>

<template>
  <div v-if="loaded" class="league-page">
    <div class="league">
      <div class="league-header">
        <div class="league-logo"></div>
        <div class="league-title"></div>
        <div class="league-header-right">
          <div class="league-invoiced-txt">kwalificatie</div>
          <div class="league-dates">{{formatDate(get_league.start_date)}} T/M {{formatDate(get_league.end_date)}}</div>
        </div>
      </div>
      <div class="league-content">
        <div class="league-partners">
          <template v-for="(item) in splittedData[currentPage].slice(0,20)" :key="item.user_id">
          <div class="league-partner" :class="{'yellow-line': item.rank <= 15, 'elite-line': item.rank > 15 && item.rank < 76 }">
            <div class="league-inner">
                <div class="league-rank">{{item.rank}}</div><div league-name="league-name">{{ item.first_name }} {{ item.last_name }}</div><div class="league-amount">&euro; {{ formatFee(item.amount) }}</div>
                <!-- <div class="league-progress" :style="{ 'width': getProgress(item.amount) + '%'}"></div> -->
            </div>
          </div>
          </template>
        </div>
        <div class="league-partners">
          <template v-for="(item) in splittedData[currentPage].slice(20,40)" :key="item.user_id">
          <div class="league-partner" :class="{'yellow-line': item.rank <= 15, 'elite-line': item.rank > 15 && item.rank < 76 }">
            <div class="league-inner">
                <div class="league-rank">{{item.rank}}</div><div league-name="league-name">{{ item.first_name }} {{ item.last_name }}</div><div class="league-amount">&euro; {{ formatFee(item.amount) }}</div>
                <!-- <div class="league-progress" :style="{ 'width': getProgress(item.amount) + '%'}"></div> -->
            </div>
          </div>
          </template>
        </div>
        <div class="league-partners">
          <template v-for="(item) in splittedData[currentPage].slice(40,60)" :key="item.user_id">
            <div class="league-partner" :class="{'yellow-line': item.rank <= 15, 'elite-line': item.rank > 15 && item.rank < 76 }">
              <div class="league-inner">
                <div class="league-rank">{{item.rank}}</div><div league-name="league-name">{{ item.first_name }} {{ item.last_name }}</div><div class="league-amount">&euro; {{ formatFee(item.amount) }}</div>
                <!-- <div class="league-progress" :style="{ 'width': getProgress(item.amount) + '%'}"></div> -->
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="extra"></div>
  </div>
  <AlternativeFooter :pager="pager" :currentPage="currentPage"/>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow:wght@700&display=swap');

html, body {
  height: 100%;
}
#app {
  height: 100%;
}
#wrapper {
  height: 100%;
}



/* .league-destination {
  background-image: url('/img/diamond-big.png');
  width: 100%;
  height: 100%
} */
.league-header {
  padding: 0px 16px;

  height: 84px;
}
.league-invoiced-txt{
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: right;
}
.league-dates{
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: right;
}
.green-line {
  border-color: #45e4bf;
  border-left-width: 6px;
  border-left-style: solid;
}

.orange-line {
  border-color: #fa9a00;
  border-left-width: 6px;
  border-left-style: solid;
}

.yellow-line {
  border-color: #C2C36C;
  border-left-width: 6px;
  border-left-style: solid;
}

.elite-line {
  border-color: #0C2E5C;
  border-left-width: 6px;
  border-left-style: solid;
}

.league-amount {
  margin-left: auto;
}



.league-rank {
  margin-right: 16px;
  width: 19px;
}
.league-name {
  margin-right: 9px;
}

.league-page {
  display: flex;
  flex-direction: row;
  padding: 16px;
  color: rgba(255, 255, 255, 0.87);
  font-size: 14px;
  justify-content: space-between;
}
.league {
  display: flex;
  width: 66%;
  flex-direction: column;
  justify-content: flex-start;
}
.extra {
  background: url('https://improvers-prod-images-bucket.s3.eu-central-1.amazonaws.com/scorebord/Qualification.png');
  width: 33%;
  margin-left: 9px;
  background-size: cover;
  height: 980px;
}

.league-header {
  display: flex;
  background: hsla(0, 0%, 100%, 0.08);
  background-image: url('/img/elite-header.png');
  background-repeat: no-repeat;
  margin-bottom: 16px;
}
.league-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.league-partners {
  background: rgba(255, 255, 255, 0.1);
  width: 32.5%;
}

.league-title {
  font-family: Archivo Narrow;
  font-size: 60px;
  font-weight: 700;
  line-height: 81px;
  letter-spacing: 0em;
  text-align: left;
}
.league-logo {
  background-image: unset;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  height: 100%;
  width: 10%;
}



.league-partner {
  box-sizing: border-box;
}
.league-inner {
  display: flex;
  border-bottom-style: solid;
  border-bottom-width: 1px;

  padding-top: 13px;
  padding-bottom: 13px;
  margin-left: 13px;
  margin-right: 13px;
  line-height: 17px;
  position: relative;
}
.league-progress {
  position: absolute;
  height: 1px;
  background-color:rgba(250, 154, 0, 1);

  left: 0;
  bottom: -0px;
}

.league-header-right {
  margin-left: auto;
  margin-top: 20px;
}



</style>


<script>

import { useStore } from 'vuex'
import { onMounted, ref, computed } from 'vue'
import { useFetch } from '@/composables/useFetch';
import AlternativeFooter from '@/components/AlternativeFooter.vue';
import shared from '../shared/shared.js';

export default {
  components: {
    AlternativeFooter
  },
  setup() {
    const store = useStore();
    const { formatFee } = shared()
    const chunk = 60

    const pageCount = computed(() => {
      return splittedData.value.length
    })
    //console.log(store.state.contacts.filter((el) => el.ext_sf_certified_partner_id == null))
    const currentPage = ref(0)
    let slide_timer = undefined

    const loadData = async () => {

      const league = await useFetch(process.env.VUE_APP_QUALIFICATION, { addQueryParams: true }).then((response) => response.json())
      if (league.data.length > 0) {
        store.dispatch('setQualification', league.data[0]);
        console.log(store.state.qualification)
        const filter0 = `&filter={"league":{"_eq":${league.data[0].id}}}`
        const filter1 = `&filter={"league_id":{"_eq":${league.data[0].id}}}`

        const league_data_url = `${process.env.VUE_APP_QUALIFICATION_DATA}${filter0}`;
        const league_compliance_url = `${process.env.VUE_APP_QUALIFICATION_COMPLIANCE}${filter1}`;
        const league_reviews_url = `${process.env.VUE_APP_QUALIFICATION_REVIEWS}${filter1}`;

        const league_data = useFetch(league_data_url).then((response) => response.json())
        const league_compliance = useFetch(league_compliance_url).then((response) => response.json())
        const league_reviews = useFetch(league_reviews_url).then((response) => response.json())

        await Promise.all([league_data, league_compliance, league_reviews]).then((fetched) => {
          store.dispatch('setQualificationData', fetched[0].data);
          store.dispatch('setQualificationCompliance', fetched[1].data);
          store.dispatch('setQualificationReviews', fetched[2].data);
          loaded.value = true
        })


      }
    }

    function doSlide(direction='up') {

        if (direction == 'up') {
            currentPage.value = currentPage.value == pageCount.value - 1 ? 0 : currentPage.value + 1;
        }
        else {
            currentPage.value = currentPage.value == 0 ? pageCount.value - 1 : currentPage.value - 1;
        }
    }


      function setupSlideTimer() {
      if (!slide_timer) {
      slide_timer = setInterval(() => {
          doSlide();
      }, 12000);
      }
      }

    const getProgress = (amount) => {
      // const league = get_league.value;
      const amount_checked = amount > 0 ? amount : 0;
      return Math.round(Math.min(amount_checked / store.state.qualification.target,1) * 100);
    }

    const formatDate = (date) => {
      const dt = new Date(date)
      let options = {
        month: "long",
        day: "numeric",
      };

      return new Intl.DateTimeFormat("nl-NL", options).format(dt).toUpperCase();
    }


    const get_league_data_new = computed(() => {
      return store.state.qualification_data.reduce((acc,cur) => {
          const compliance = store.state.qualification_compliance.find((el)=> el.partner_id === cur.user_id) || null //returns null if undefined
          const reviews = store.state.qualification_reviews.find((el)=> el.partner_id === cur.user_id) || null
          const contact = store.state.contacts.find((el) => el.ext_sf_partner_id === cur.user_id) || null
          const compliance_score = compliance !== null ? compliance.compliance_pct : null
          const review_score = reviews !== null ? reviews.score : null
          const is_compliant = compliance !== null ? compliance_score >= 90 : true
          const quality_ok = reviews !== null ? review_score >= 3.5 : true

          if (contact == null) {
            console.log('no contact',cur.user_id )
          } else if (contact.has_signed_partner_agreement != 'True') {
            console.log('no povk',cur.user_id, contact.first_name, contact.last_name )
          } else if (cur.amount < 1) {
            console.log('lower than 1',cur.user_id, contact.first_name, contact.last_name )
          } else {
            acc.push({
              "user_id": cur.user_id,
              "amount": cur.amount,
              "first_name": cur.user ? cur.user.first_name  : null,
              "last_name": cur.user ? cur.user.last_name : null,
              "compliance_score": compliance_score,
              "is_compliant": is_compliant,
              "quality_ok": quality_ok,
              "qualified": is_compliant && quality_ok
            })
          }
          return acc
        },[])
        .filter((el) => el.qualified === true)
        .sort((a, b) => (b.amount - a.amount))
    })

    const data = computed(() => {
      return get_league_data_new.value.map((el, index) => {
          return {
            "rank": index + 1,
            "user_id": el.user_id,
            "amount": el.amount,
            "first_name": el.first_name,
            "last_name": el.last_name,
            "compliance_score": el.compliance_score,
            "is_compliant": el.is_compliant,
            "quality_ok": el.quality_ok,
            "qualified": el.qualified
            }
          }
        )
      })

    let splittedData = computed(() => {
        const x = data.value.reduce((acc, cur, index) => {
            if (index % chunk === 0) {
                acc.push(data.value.slice(index, index + chunk));
            }
            return acc;
        }, []);

        // console.log(x, chunk)
        return x
    })

    const pager = computed(() => {
      return splittedData.value.map((el, index) => {
        const first = el[0].rank;
        const last = el[el.length-1].rank;
        return {
          "page": index,
          "data": first + ' t/m ' + last
          }
        }
      )}
   )

    const get_league = computed(function () {
      return store.state.qualification;
    })

    const loaded = ref(false)


    onMounted(async function () {
     if(store.state.qualification === null) {
        await loadData()
        setupSlideTimer()

     }

    })

    return {loaded, get_league, splittedData, formatDate, formatFee, getProgress, pager, currentPage, pageCount}
  }
}
</script>
import router from "@/router";
import store from "@/store";

export const useFetch = (url, params = {}) => {
  if (!params.addQueryParams) {
    params.addQueryParams = false;
  }
  if (!params.useToken) {
    params.useToken = true;
  }

  const query = router.currentRoute.value.query;
  const urlObj = new URL(url);
  const token = store.getters.getAccessToken;
  const headers = {};

  if (params.useToken && token) {
    headers.Authorization = "bearer " + token;
  }

  if (!token && query.token) {
    urlObj.searchParams.append("token", query.token);
  }

  if (params.addQueryParams && Object.keys(query).length > 0) {
    Object.keys(query).forEach((key) => {
      if (key === "token") return;
      urlObj.searchParams.append(key, query[key]);
    });
  }

  if (params.certifiedPartnerId) {
    urlObj.searchParams.append(
      "certified_partner_id",
      params.certifiedPartnerId
    );
  }

  return fetch(urlObj.href, { headers });
};

<template>
  <div id="wrapper">
    <!--
    Valentijns hartjes
    <div class="hearts">
      <div class="heart"></div>
      <div class="heart"></div>
      <div class="heart"></div>
      <div class="heart"></div>
    </div> -->

    <div v-if="!loaded" class="x-reset-container">
      <!-- <div class="x-reset-container"> -->
      <div class="im">
        <img :src="loaderLogo" style="height: 180px;" />

        <div class="im__fill"></div>
      </div>
      <div class="errors">
        <div v-for="(item, index) in errors" :key="index">{{ item }}</div>
      </div>
      <div class="tasks">
        <div v-for="(item, index) in tasks" :key="index">{{ item }}</div>
      </div>
    </div>
    <router-view v-if="loaded" />
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

import { nextTick, onMounted, computed, ref, onUnmounted } from 'vue'
import shared from './shared/shared.js'
import { Vertical } from './enums/vertical-enum.js';
import { useFetch } from './composables/useFetch';
// import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
// import { EventSourcePolyfill } from 'event-source-polyfill';

export default {
  setup() {

    const { getSSEUrl } = shared()
    let event_source = null
    let clock_timer = false
    let errors = ref([])
    let tasks = ref([])

    // errors.value.push('test')

    const router = useRouter()
    const route = useRoute()

    const store = useStore();
    const loaded = computed(() => store.state.loaded);

    function loadPartOne() {
      const dataSummaryPartner = useFetch(process.env.VUE_APP_SUMMARY_PARTNER, { addQueryParams: true }).then((response) => response.json()).catch((err) => { getErr(err, 'sumpart') })
      const dataSummaryTeams = useFetch(process.env.VUE_APP_SUMMARY_TEAMS, { addQueryParams: true }).then((response) => response.json()).catch((err) => { getErr(err, 'sumteams') })
      const dataDetailPartner = useFetch(process.env.VUE_APP_DETAIL, { addQueryParams: true }).then((response) => response.json()).catch((err) => { getErr(err, 'detail') })
      const compliance = useFetch(process.env.VUE_APP_COMPLIANCE).then((response) => response.json()).catch((err) => { getErr(err, 'compl') })
      const targets = useFetch(process.env.VUE_APP_TARGETS).then((response) => response.json()).catch((err) => { getErr(err, 'targ') })
      const dataContacts = useFetch(process.env.VUE_APP_CONTACT_ENDPOINT).then((response) => response.json()).catch((err) => { getErr(err, 'cont') })
      const revenueSummary = useFetch(process.env.VUE_APP_SALES_TOTAL, { addQueryParams: true }).then((response) => response.json()).catch((err) => { getErr(err, 'revsum') })
      const awards = useFetch(process.env.VUE_APP_AWARDS).then((response) => response.json()).then((data) => data.data).catch((err) => { getErr(err, 'aw') })
      const leagues = useFetch(process.env.VUE_APP_PARTNER_LEAGUES).then((response) => response.json()).then((data) => data.data).catch((err) => { getErr(err, 'leagues') })
      //Promise.all(
      PromiseAllSync(
        [dataSummaryPartner, dataSummaryTeams, dataDetailPartner, compliance, targets, dataContacts, revenueSummary, awards, leagues],
        //[dataSummaryPartner, dataDetailPartner, compliance, targets, dataContacts, revenueSummary],
      ).then((fetched) => {
        fetched.forEach(response => {
          if (response.detail) {
            errors.value.push(response.detail)
            return Promise.reject()
          }
        });
        store.dispatch('setSummaryPartners', fetched[0]);
        tasks.value.push(1)
        store.dispatch('setSummaryTeams', fetched[1]);
        tasks.value.push(2)
        store.dispatch('setDetails2', fetched[2]);
        tasks.value.push(2)
        store.dispatch('setCompliance', fetched[3]);
        tasks.value.push(3)
        store.dispatch('setTargets', fetched[4]);
        tasks.value.push(4)
        store.dispatch('setContacts', fetched[5]);
        tasks.value.push(5)
        store.commit('setRevenueSummary', fetched[6]);
        tasks.value.push(6)
        store.commit('setAwards', fetched[7]);
        store.commit('setLeagues', fetched[8]);
        loadPartTwo();
      }
      ).catch((err) => {
        errors.value.push(err)
      })
    }


    async function PromiseAllSync(resolvables) {
      const results = []
      for (const resolvable of resolvables) {
        let item = await Promise.resolve(resolvable)
        results.push(item)
      }
      return results;
    }

    function getErr(err, name) {
      errors.value.push(`${name} ${err}`)
    }

    function loadPartTwo() {
      const dataFeeds = useFetch(process.env.VUE_APP_FEED_ENDPOINT).then((response) => response.json()).catch((err) => { getErr(err, 'feeds') });
      const dataAdvertisers = useFetch(process.env.VUE_APP_ADVERTISERS).then((response) => response.json()).catch((err) => { getErr(err, 'adv') });
      // const dataSummaryAdvertisers = useFetch(process.env.VUE_APP_SUMMARY_ADVERTISER, { addQueryParams: true }).then((response) => response.json()).catch((err) => { getErr(err, 'summadv') });
      const dataReviews = useFetch(process.env.VUE_APP_REVIEWS).then((response) => response.json()).catch((err) => { getErr(err, 'reviews') });
      const dataCharts = useFetch(process.env.VUE_APP_CHARTS, { addQueryParams: true }).then((response) => response.json()).catch((err) => { getErr(err, 'charts') });
      /*Promise.all(*/
      PromiseAllSync(
        // [dataFeeds, dataAdvertisers, dataSummaryAdvertisers, dataReviews, dataCharts],
        [dataFeeds, dataAdvertisers, dataReviews, dataCharts],
      ).then((fetched) => {
        store.dispatch('setFeeds', fetched[0])
        tasks.value.push(7)
        store.dispatch('setAdvertisers', fetched[1])
        tasks.value.push(8)
        // store.dispatch('setSummaryAdvertisers', fetched[2])
        // tasks.value.push(9)
        store.dispatch('setReviews', fetched[2])
        tasks.value.push(10)
        store.dispatch('setCharts', fetched[3])
        tasks.value.push(11)
        setTimeout(async () => {
          store.dispatch('setLoaded', true)
        }, Math.floor(1000));
      }
      ).catch((err) => {
        errors.value.push(err)
      })
      /*some async for later pages */

    }

    function setCurrentTime(currentTime) {
      // console.log(currentTime)
      store.dispatch('setCurrentTime', currentTime);
    }

    function startClock() {
      if (clock_timer === false) {
        clock_timer = setInterval(function () {
          const d = new Date();
          const hh = String(d.getHours()).padStart(2, '0');
          const mm = String(d.getMinutes()).padStart(2, '0');
          setCurrentTime(`${hh}:${mm}`);
        }, 1000);
      }
    }

    const loaderLogo = computed(function () {
      const theme = store.state.theme;
      if (theme == 'improvers') {
        return 'https://improvers-prod-images-bucket.s3.eu-central-1.amazonaws.com/logo/rgb_im_sapphire.svg';
      } else {
        return 'https://improvers-prod-images-bucket.s3.eu-central-1.amazonaws.com/logo/rgb_' + theme + '_beeldmerk_square_darkpurple.svg';
      }
    });

    onUnmounted(() => {
      clock_timer = null;
    })



    onMounted(async function () {
      /// set timeout
      const random = Math.random()
      console.log('random', random)

      const theme = new URL(window.location.href).searchParams.get("theme");
      if (!theme || theme === 'improvers') {
        store.dispatch('setTheme', 'improvers')

      } else {
        store.dispatch('setTheme', 'cherries')
      }



      setTimeout(async () => {

        await loadPartOne();
        setupStream();
        startClock();

        const href = location.href
          .replace(/[&?]code=[^&$]*/, '')
          .replace(/[&?]scope=[^&$]*/, '')
          .replace(/[#?]state=[^&$]*/, '')
          .replace(/[&?]session_state=[^&$]*/, '');
        router.replace(href)
      }, 1);
      //}, Math.floor(random * 5000));



    })

    function setupStream() {

      const token = store.getters.getAccessToken;
      if (token) {
        useFetch(process.env.VUE_APP_SOFT_TOKEN).then(
          (response) => {
            response.json().then((json) => {
              handle_event_source(getSSEUrl(process.env.VUE_APP_SSE_ENDPOINT) + json.token + "/")
            })
          }
        ).catch((error) => {
          console.error('Error:', error);
        });
      } else {
        event_source = handle_event_source(getSSEUrl(process.env.VUE_APP_SSE_ENDPOINT));
      }

      function handle_event_source(url) {
        event_source = new EventSource(url);
        event_source.addEventListener('message',
          (event) => {
            const data = JSON.parse(event.data);
            if (data.message_type === 'sale') {
              // store.dispatch('upsertCurrentSale', data);
              faster_process(data);

            } else {
              store.distpatch('upsertContact', data);
            }
          }, false);
        event_source.addEventListener('heartbeat',
          (event) => {
            if (store.getters.getDate) {
              if (!event.data.includes(store.getters.getDate)) {
                window.location.reload();
              }
            }
          }, false);
      }

      function is_vertical_match(data) {
        const vertical = Vertical[data.vertical];
        const query = route.query
        if (!query.vertical) return true;

        if (query.vertical !== vertical) {
          console.log('Skipping add sale, vertical mismatch', vertical, query.vertical)
          return false;
        }
        return true;
      }

      function is_campaign_match(data) {
        const query = route.query
        if (!query.campaignCodes) return true;

        const partialCampaignCode = data.campaign_code.substring(0, 4)
        const campaignCodes = query.campaignCodes.split(',')

        if (!campaignCodes.includes(partialCampaignCode) && !campaignCodes.includes(data.campaign_code)) {
          console.log('Skipping add sale, campaign code mismatch', data.campaign_code, campaignCodes)
          return false;
        }

        return true;
      }

      function faster_process(data) {
        if (!is_vertical_match(data)) return;
        if (!is_campaign_match(data)) return;
        store.dispatch('upsertCurrentSaleTemp', data);

        if (store.state.inloop == 0) {
          store.state.inloop = 1
          temp_loop()
        }

      }

      function temp_loop() {
        if (store.state.inloop == 1) {
          console.log('size before', store.state.temp_sales.length)
          const sale = store.state.temp_sales.shift()
          store.dispatch('upsertCurrentSale', sale);
          nextTick(() => {
            console.log('next tick')
            if (store.state.temp_sales.length == 0) {
              store.state.inloop = 0
              console.log('loop stopped')
            } else {
              console.log('still looping')
              temp_loop()
            }
          })
        }
      }
    }
    return { loaded, errors, tasks, loaderLogo }
  },
}

</script>


<style>
:root {
  --color-heart: red;
  --size-viewport: 85%;
}

.hearts {
  bottom: 0%;
  left: 70%;
}

.hearts {
  width: 5%;
  min-width: 100px;
  height: 25%;
  min-height: 100%;
  position: absolute;
  z-index: 10;
}

.heart {
  background-color: var(--color-heart);
  position: absolute;
  height: 20%;
  min-height: 15px;
  width: 20%;
  min-width: 15px;
  opacity: 0;
  -webkit-mask-image: url('~@/assets/images/heart_mask.svg');
  mask-image: url('~@/assets/images/heart_mask.svg');
  /* -webkit-mask-repeat: no-repeat; */
  mask-repeat: no-repeat;
  /* -webkit-mask-position: bottom; */
  mask-position: bottom;
  animation: floating-heart 10s infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
}

.heart:nth-child(1) {
  animation-delay: 1s;
}

.heart:nth-child(2) {
  animation-delay: 2.5s;
}

.heart:nth-child(3) {
  animation-delay: 3.5s;
}

.heart:nth-child(4) {
  animation-delay: 4.5s;
}

@keyframes floating-heart {
  0% {
    opacity: 0;
    bottom: 0%;
    left: 0%;
  }

  10% {
    opacity: 1;
    bottom: 20%;
    left: 70%;
  }

  20% {
    bottom: 40%;
    left: 10%;
  }

  30% {
    bottom: 60%;
    left: 50%;
  }

  40% {
    opacity: 1;
    bottom: 80%;
    left: 5%;
  }

  48% {
    opacity: 0;
    bottom: 100%;
    left: 60%;
  }

  100% {
    opacity: 0;
    bottom: 100%;
    left: 90%;
  }
}
</style>


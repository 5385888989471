import { createRouter, createWebHistory } from "vue-router";
import PartnerBig from "../views/PartnerBig2.vue";
import Recent from "../views/Recent.vue";
import Charity from "../views/Charity.vue";
import Team from "../views/Team.vue";
import TeamAll from "../views/TeamAll.vue";
import TeamCherries from "../views/TeamCherries.vue";
import Detail from "../views/Detail.vue";
import RevenueSummary from "../views/RevenueSummary.vue";
import StatsCherries from "../views/StatsCherries.vue";
import ChampionsView from "../views/ChampionsView.vue";
import DiamondsView from "../views/DiamondsView.vue";
import QualificationView from "../views/QualificationView.vue";
import QualificationBrutoView from "../views/QualificationBrutoView.vue";


const routes = [
  {
    path: "/",
    name: "PartnerBig",
    component: PartnerBig,
  },
  {
    path: "/recent",
    name: "Recent",
    component: Recent,
  },
  {
    path: "/champions",
    name: "Champions",
    component: ChampionsView,
  },
  {
    path: "/diamond",
    name: "Diamond",
    component: DiamondsView,
  },
  {
    path: "/qualification",
    name: "Qualification",
    component: QualificationView,
  },
  {
    path: "/qualification-bruto",
    name: "QualificationBruto",
    component: QualificationBrutoView,
  },
  {
    path: "/charity",
    name: "Charity",
    component: Charity,
  },
  {
    path: "/detail",
    name: "Detail",
    component: Detail,
  },
  {
    path: "/team",
    name: "Team",
    component: Team,
  },
  {
    path: "/team-all",
    name: "TeamAll",
    component: TeamAll,
  },
  {
    path: "/team-cherries",
    name: "TeamCherries",
    component: TeamCherries,
  },
  {
    path: "/revenue-summary",
    name: "RevenueSummary",
    component: RevenueSummary,
  },
  {
    path: "/stats-cherries",
    name: "StatsCherries",
    component: StatsCherries,
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

<template>
    <div v-if="loaded">
        <main class="team-cherries grid-3-cols">
            <!-- todo toggle between 2 and 4 -->
            <div class="col-1 col">
            <AdvertiserCherries :active-slide="active_slide" :certifiedPartnerId="certifiedPartnerId" :header="'Cherries'"/>
                <div>
                    <h1 class="team-l">Doelstellingen Cherries</h1>
                    <h2 class="team-l">Aantallen</h2>
                </div>
                <div>
                    <RevenueBlock v-for="item in targetGet" :key="item.advertiser" :toggle="nav_toggle"
                        :header="item.advertiser" :revenue="item.scores" :targets="item.targets" :showEuro="false" />
                </div>
            </div>


            <div class="col-2 col">
                <div v-if="loaded" class="is-hidden-mobile ">
                    <TeamTargets :revenue="team_revenue" :target="team_target" :thisTeam="thisTeam" />
                </div>
            </div>

            <div class="col-3 col">
                <TopPartnersNew :thisTeam="thisTeam" :certifiedPartnerId="certifiedPartnerId"/>
            </div>

        </main>
        <Footer />
    </div>
</template>

<script>
import { onMounted, computed, ref } from 'vue'
import Footer from '../components/Footer.vue';
import RevenueBlock from '../components/RevenueBlock.vue';
import TeamTargets from '../components/TeamTargets.vue';
import TopPartnersNew from '../components/TopPartnersNew.vue';
import { useStore } from 'vuex'
import shared from '../shared/shared.js'
// import LastSale from '../components/LastSale.vue';
import AdvertiserCherries from '../components/AdvertiserCherries.vue';

export default {
    name: 'Team',
    components: {
        Footer,
        RevenueBlock,
        // LastSale,
        TopPartnersNew,
        TeamTargets,
        AdvertiserCherries
    },
    setup() {
        const thisTeam = 'Cherries';
        const loaded = computed(() => store.state.loaded);
        const store = useStore()
        // const toggle = ref(true);
        // const toggle_timer = ref(false);
        const { getItemComplianceClass, getRowComplianceClass, formatCompliance } = shared()
        const certifiedPartnerId = '28835'
        const active_slide = ref(2);
        const nav_interval = ref(false);
        const nav_toggle = ref(false);

        function setupTeams() {
            const url_team = new URL(window.location.href).searchParams.get('team');
            if (url_team) {
                thisTeam.value = url_team
            }
            store.dispatch('setTeams', process.env.VUE_APP_TEAMS.split(','));
        }

        function navigate() {
            if (nav_interval.value === false) {
                nav_interval.value = setInterval(() => {
                    if (active_slide.value == 2) {
                        nav_toggle.value = true;
                        active_slide.value = 4;
                        console.log('active_slide.value', active_slide.value)
                    } else {
                        nav_toggle.value = false;
                        active_slide.value = 2;
                        console.log('active_slide.value', active_slide.value)
                    }
                }, 12000);
            }
        }

        // const topPartners = computed(function () {
        //     // return store.getters.partnerBig.filter((el) => el.team.toLowerCase() === thisTeam.value.toLowerCase()).slice(0, 24)

        //     return store.getters.partnerBig.filter((el) => el.certified_partner_id === certifiedPartnerId).slice(0, 24)
        // })

        onMounted(function () {
            // setupTimer()
            setupTeams()
            navigate()
        })

        const team_revenue = computed(function () {
            // console.log('team_rev', store.getters.getSummaryTeams)
            return store.getters.getSummaryTeams.find((el) => el.team.toLowerCase() === thisTeam.toLowerCase());
        })

        const team_target = computed(function () {
            const val = store.getters.getTargets.find((el) => String(el.team).toLowerCase() === thisTeam.toLowerCase());
            console.log('target',store.getters.getTargets.find((el) => String(el.team).toLowerCase() === thisTeam.toLowerCase()) )
            if (val) {
                return val
            } else {
                return { iso_month: 0, iso_week: 0, iso_year: 0, month_target: 0, quarter_target: 0, team: thisTeam.value.toLowerCase(), week_target: 0, year_target: 0, half_year_target: 0 }
            }
        })


        const targetGet = computed(function () {
            const start = store.getters.getTargets.filter((el) => el.certified_partner_id === certifiedPartnerId)
            const retval = start.reduce((acc, cur) => {
                let advertiser = store.state.advertisers.find((el) => el.code === cur.advertiser);
                let targets = { 'week_target': cur.week_count, 'month_target': cur.month_count, 'quarter_target': cur.quarter_count, 'half_year_target': cur.half_year_count }
                // let scores = store.getters.getSummaryAdvertisers(certifiedPartnerId).filter((el) => el.advertiser === cur.advertiser).map((el) => {
                //     return { 'week': el.cnt_this_week, 'month': el.cnt_this_month, 'quarter': el.cnt_this_quarter, 'half_year': el.cnt_this_half_year }
                // })

                let scores = store.getters.totalAll(certifiedPartnerId)
                console.log('scores',scores)

                let adv = advertiser ? advertiser.name : ''
                acc.push(
                    {
                        'advertiser': adv,
                        'targets': targets,
                        'scores': scores ? {
                            'today': scores.cnt_today,
                            'week': scores.cnt_this_week,
                            'month': scores.cnt_this_month,
                            'quarter': scores.cnt_this_quarter,
                            'half_year': scores.cnt_this_half_year
                        } : { 'today':0, 'week': 0, 'month': 0, 'quarter': 0, 'half_year': 0 }
                    }
                )
                return acc;
            }, [])
            return retval;
        })

        // function setupTimer() {
        //     if (toggle_timer.value === false) {
        //         toggle_timer.value = setInterval(() => {
        //             toggle.value = !toggle.value
        //         }, 12000);
        //     }
        // }

        return { team_revenue, team_target, getItemComplianceClass, getRowComplianceClass, formatCompliance, certifiedPartnerId, targetGet, nav_toggle, active_slide, loaded, thisTeam }
    },
}
</script>

<template>
  <div>
    <main>
      <div class="revsum">
        <h2>Cordaid RTL Boulevard</h2>
        <h1>&euro; {{ formatFee(revenueSummary.total_revenue) }}</h1>
      </div>
      <!-- <div class="mt-3">
        <h2>Sales</h2>
        <h1>{{ revenueSummary.total_sales }}</h1>
      </div> -->
    </main>
    <Footer />
  </div>
</template>

<script>
import Footer from '../components/Footer.vue';
import { useStore } from 'vuex';
import Mixin from '../shared/mixin';

export default {
  name: 'RevenueSummary',
  components: {
    Footer,
  },
  setup() {
    const store = useStore();

    return {
      revenueSummary: store.state.revenueSummary,
    };
  },
  methods: {
    formatFee: Mixin.methods.formatFee,
  },
};
</script>

<style scoped>
main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 84vh;
  flex-direction: column;
  text-align: center;
}

h1 {
  font-size: 6rem;
  font-weight: 700;
}

h2 {
  font-size: 3rem;
  font-weight: 700;
}

.mt-3 {
  margin-top: 3rem;
}
</style>
